import { NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export enum StateBadgeEnum {
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
	NEUTRAL = 'neutral',
}

@Component({
	selector: 'app-state-badge',
	templateUrl: 'state-badge.component.html',
	standalone: true,
	imports: [MatIconModule, NgIf],
	styleUrls: ['state-badge.component.scss'],
	host: {
		class: 'flex',
	},
})
export class StateBadgeComponent {
	stateBadgeEnum = StateBadgeEnum;
	state = input<StateBadgeEnum>(StateBadgeEnum.NEUTRAL);
}
