<mat-icon
	*ngIf="state() === stateBadgeEnum.SUCCESS"
	class="icon-size-4 green-shadow text-green-100 bg-green-500 rounded-full"
	svgIcon="check"></mat-icon>

<mat-icon
	*ngIf="state() === stateBadgeEnum.WARNING"
	class="icon-size-4 yellow-shadow text-yellow-100 bg-yellow-500 rounded-full"
	svgIcon="priority_high"></mat-icon>

<mat-icon
	*ngIf="state() === stateBadgeEnum.ERROR"
	class="icon-size-4 red-shadow text-red-100 bg-red-500 rounded-full"
	svgIcon="close"></mat-icon>

<mat-icon
	*ngIf="state() === stateBadgeEnum.NEUTRAL"
	class="icon-size-4 neutral-shadow text-neutral-100 bg-neutral-500 rounded-full"
	svgIcon="remove"></mat-icon>
