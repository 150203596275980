import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarModule,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { FuseAlertModule } from '@fuse/components/alert';
import { Subject } from 'rxjs';
import {
	StateBadgeEnum,
	StateBadgeComponent,
} from '../../../../shared/components/state-badge/state-badge.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
	selector: 'success-alert',
	standalone: true,
	imports: [
		MatSnackBarModule,
		FuseAlertModule,
		MatIconModule,
		StateBadgeComponent,
		TranslocoModule,
	],
	templateUrl: './success-alert.component.html',
	styleUrls: ['./success-alert.component.scss'],
	host: {
		class: 'flex justify-end',
	},
})
export class SuccessAlertComponent {
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _snackBarRef: MatSnackBarRef<any>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any,
	) {}

	stateBadgeEnum = StateBadgeEnum;
}
