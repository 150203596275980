<div
	class="bg-green-100 dark:bg-green-700 border border-green-600 py-2 px-4 flex gap-2 rounded-full w-fit items-center">
	<app-state-badge [state]="stateBadgeEnum.SUCCESS" />
	<p class="text-green-900 dark:text-green-100">
		{{ data.message | transloco }}
	</p>
	<a
		class="text-gray-900 underline"
		[routerLink]="['./admin', data.moduleUrl, data.id, 'overview']"
		>{{ data.code }}</a
	>
</div>
